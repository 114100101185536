$custom-colors: (
  "dark-grey": #272727,
  "mid-grey": #868686,
  "light-grey": #dbdbdb,
  "off-white": #f6f6f6,
  "success": #35a051,
  "error": #c1002d,
  "teal": #014f5d,
  "mustard": #cba12a,
  "gold": #af8434,
  "pink-red": #de1e59,
  "amber": #e28d06,
  "turquoise": #08675c
);

:export {
  @each $key, $value in $custom-colors {
    #{unquote($key)}: $value;
  }
}